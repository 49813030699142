import {
    displayRoute,
    geolocateMe,
    initMap,
    refreshMap,
    displayOptions,
    displayOptionsSpe,
    slimSelectDishOptionsList,
    hideOptions
} from "./my-helper";
import $ from "jquery";
import SlimSelect from 'slim-select'
import Swal from "sweetalert2";
import TomSelect from "tom-select";

Window.handleSelectChange = handleSelectChange;
Window.initMap = initMap;
Window.geolocateMe = geolocateMe;
Window.displayRoute = displayRoute;
Window.refreshMap = refreshMap;
Window.displayOptions = displayOptions;
Window.displayOptionsSpe = displayOptionsSpe;
Window.slimSelectDishOptionsList = slimSelectDishOptionsList;
Window.hideOptions = hideOptions;
Window.showAddress = showAddress;
Window.sortRestaurantCategory = sortRestaurantCategory;
Window.hovercat = hovercat;
Window.resetcat = resetcat;
Window.showProductMenu = showProductMenu;
Window.updateAddressName = updateAddressName;
Window.updateAddressNameRegister = updateAddressNameRegister;
Window.showDeliveryFeesNoteOnCart = showDeliveryFeesNoteOnCart;
Window.displayWarnCartTogo = displayWarnCartTogo;
Window.displayWarnConfirmPurchaseTogo = displayWarnConfirmPurchaseTogo;
Window.jumpTo = jumpTo;
window.tomDishOption = tomDishOption;


export function showAddress() {
    $('#new-address').removeClass('d-none');
    $("#checkout_addresses_placeholder").prop("checked", true);
    //$('#new-address-btn').addClass('d-none');
}

export  function jumpTo(anchor_id){

    let element;
    element = document.getElementById("scroll" + anchor_id)
    // element.scrollIntoView({behavior: "smooth", block: "center", inline: "center"})

   // element.classList.add("bg-primary-lighten");
    element.scrollIntoView({ behavior: "smooth", block: "center", inline: "end" });

}

$('#navbar1').on('click', function () {
    if ($('#navbar1').attr('aria-expanded') == "true") {
        location.href = $('#navbar1').attr('href');
    }
});

$('#navbar2').on('click', function () {
    if ($('#navbar2').attr('aria-expanded') == "true") {
        location.href = $('#navbar2').attr('href');
    }
});

$(document).ready(function () {
    $('.js-product-autocomplete').each(function () {

        var autocompleteUrl = $(this).data('autocomplete-url');
        var noProductInSupplier = false;
        $(this).autocomplete(
            {
                hint: false,
                clearOnSelected: true,
            }, [
                {
                    templates: {
                        suggestion: function (suggestion) {

                            if(suggestion.name === 'NO_RESULT_en'){
                                return 'No results found'

                            }
                            if(suggestion.name === 'NO_RESULT_fr'){
                                return 'Aucun Résultat'

                            }
                            if(suggestion.name === 'NO_RESULT_SUPPLIER_en'){
                                noProductInSupplier = true
                                return 'No Product Found For This Supplier'

                            }
                            if(suggestion.name === 'NO_RESULT_SUPPLIER_fr'){
                                noProductInSupplier = true
                                let one ='Aucun Produit Trouvé Chez Ce Fournisseur'
                                //let two = '<br><span class="font-weight-bold">Autres fournisseurs</span>'
                                //return one+two
                                return one;

                            }
                            else {
                                var val = suggestion.name;

                                var display1 = '<div class="" >'
                                    + '<a  data-action="click->search#addProduct" href="#" data-discr-product="SHOPPING"  data-id-product="' + suggestion.id + '" class="row">';

                                if (suggestion.picture != null) {
                                    var display2 = '<div class="col-1 text-center"><img src="https://www.delifood-stbarth.com/upload/product/' + suggestion.picture + '" height="30"/></div>';
                                } else {
                                    var display2 = '<div class="col-1"></div>';
                                }
                                if(noProductInSupplier){
                                    display2 += '<div class="col-1 text-center"><img src="https://www.delifood-stbarth.com/upload/supplier/logo/' + suggestion.supplierLogo + '" height="30"/></div>'
                                }

                                if (suggestion.price > 0) {
                                    var display3 = '<div class="col">' + suggestion.name + '</div><div class="col-3 text-right">'
                                        + (suggestion.price / 100).toFixed(2) + ' € </div><div class="col-2 text-right">'
                                        + '<img src="/build/images/front/picto-bag-plus-yellow.7694d5fd.svg" height="35"/></div>'
                                    '</a></div>';
                                } else {
                                    var display3 = '<div class="col">' + suggestion.name + '</div><div class="col-3 text-right"></div><div class="col-2 text-right"><img src="/build/images/front/picto-bag-plus-yellow.7694d5fd.svg" height="35"/></div>'
                                    '</a></div>';
                                }
                                return display1 + display2 + display3;
                            }


                        },
                        //dropdownMenu: '#my-custom-menu-template',

                        // footer: '<div class="branding">Powered by <img src="https://www.algolia.com/static_assets/images/press/downloads/algolia-logo-light.svg" /></div>'
                    },

                    source: function (query, cb) {
                        $('#spinner-shopping-search').removeClass('d-none')
                        $('#ico-shopping-search').addClass('d-none')
                        var requested = autocompleteUrl+'?q='+query

                        if(autocompleteUrl.search('idSupplier') > 0){
                            requested =   autocompleteUrl +'&q='+query
                        }

                        $.ajax({
                            url: requested
                        }).then(function (data) {
                            noProductInSupplier = false;
                            $('#spinner-shopping-search').addClass('d-none')
                            $('#ico-shopping-search').removeClass('d-none')
                            cb(data.products);
                        });

                    },


                    displayKey: 'name',
                    debounce: 500 // only request every 1/2 second
                }
            ]).on('autocomplete:selected', function (event, suggestion, dataset, context) {
        });
    });
});

export function sortRestaurantCategory(displayMode) {
    $('.cat_all').addClass('d-none');
    var inc = 0;

    $('.checkboxes_categories_' + displayMode + ' input:checked').each(function () {
        inc++;
        var catId = $(this).val();
        $('.cat_' + catId).removeClass('d-none');
        if (displayMode == 'mobile') {
            $('.cat_' + catId).removeClass('d-none');
        }
    });

    if (inc == 0) {
        $('.cat_all').removeClass('d-none');
        $('#nb_filtre').html('').removeClass("bg-yellow");
    } else {
        $('#nb_filtre').html(inc).addClass("bg-yellow");
    }
}

$('.custom-file-input').on('change', function (event) {
    var inputFile = event.currentTarget;
    $(inputFile).parent()
        .find('.custom-file-label')
        .html(inputFile.files[0].name);
});


(function ($) {
    $(function () {
        $('input.timepicker').each(function (index) {
            $(this).timepicker(
                {
                    timeFormat: 'H:mm',
                    interval: 5,
                    /*minTime: this.value,
                    maxTime: '6:00pm',
                    defaultTime: '11',*/
                    startTime: this.value,
                    dynamic: false,
                    dropdown: true,
                    scrollbar: true
                }
            );
        });
    });
})(jQuery);

export function handleSelectChange(target, productID) {

    const elements = document.getElementsByClassName(target.value + productID);
    const elementsToHide = document.getElementsByClassName("specialSelect" + productID);

    for (let i = 0; i < elementsToHide.length; i++) {

        elementsToHide[i].style.display = 'none';
        elementsToHide[i].disabled = 'disabled';
        $('.specialSelectDiv' + productID).addClass('d-none');
    }

    for (let i = 0; i < elements.length; i++) {

        if (elements[i].style.display == 'none') {
            elements[i].style.display = 'inline';
            elementsToHide[i].disabled = '';
            $('.specialSelectDiv' + productID).removeClass('d-none');
        } else {
            elements[i].style.display = 'none';
            $('.specialSelectDiv' + productID).addClass('d-none');
        }
    }
}


export function hovercat(numcat) {
    $("#cat" + numcat + "-h").removeClass("d-none");
    $("#cat" + numcat).addClass("d-none");
}

export function resetcat(numcat) {
    $("#cat" + numcat + "-h").addClass("d-none");
    $("#cat" + numcat).removeClass("d-none");
}

export function showProductMenu(parent, child) {
    $(".parentMenu" + parent).addClass("d-none");
    $(".product" + parent).addClass("d-none");
    $(".product" + child).removeClass("d-none");
}


export function updateAddressName() {
    var districtName = $("#checkout_district option:selected").text();
    $('#checkout_addressName').val(districtName);
}

export function updateAddressNameRegister() {
    var districtName = $("#registration_form_district option:selected").text();
    $('#registration_form_addressName').val(districtName);
}

export function displayWarnCartTogo(urlToRedirect, isTogo, locale) {

    if (isTogo) {
        var htmlMsgFR = "Souhaitez-vous passer votre commande <br> <strong>A EMPORTER ?</strong> <br> Votre panier sera vidé"
        var htmlMsgEn = "Would you like your order to be <br> <strong>CLICK AND COLLECT ?</strong> <br> Your cart will be cleared"
    } else {
        var htmlMsgFR = "Souhaitez-vous passer votre commande <br> <strong>EN LIVRAISON ?</strong> <br> Votre panier sera vidé"
        var htmlMsgEn = "Would you like your order to be <br> <strong>DELIVERED ?</strong> <br> Your cart will be cleared"
    }

    var btnConfirmFr = 'JE CONFIRME'
    var btnDenyFr = 'REVENIR'

    var btnDenyEn = 'BACK'
    var btnConfirmEn = 'I CONFIRM'

    var htmlMsg = htmlMsgFR
    var btnConfirm = btnConfirmFr
    var btnDeny = btnDenyFr
    if (locale === 'en') {
        htmlMsg = htmlMsgEn
        btnConfirm = btnConfirmEn
        btnDeny = btnDenyEn
    }

    var swalAlert = Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'Informations',
        html: '<span style="line-height: 2rem; font-family: \'Work Sans\', sans-serif;">' + htmlMsg + '</span>',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: btnConfirm,
        cancelButtonText: btnDeny,
        reverseButtons: true,
        timer: 1000000,

    })

    swalAlert.then((result) => {
        if (result.isConfirmed) {
            if (urlToRedirect) {
                window.location.replace(urlToRedirect)
            }

        }
    })

}

export function displayWarnConfirmPurchaseTogo(urlToRedirect, urlCancelToRedirect, locale) {
    var htmlMsgFR = "Je confirme avoir choisi le mode retrait suivant<br>"
    htmlMsgFR += "<strong>A EMPORTER</strong>"
    var btnConfirmFr = 'JE CONFIRME'
    var btnDenyFr = 'REVENIR'

    var htmlMsgEn = "I confirm that I have chosen the following method of purchase<br>"
    htmlMsgEn += "<strong>Click & Collect</strong>"
    var btnConfirmEn = 'I CONFIRM'
    var btnDenyEn = 'BACK'

    var htmlMsg = htmlMsgFR
    var btnConfirm = btnConfirmFr
    var btnDeny = btnDenyFr
    if (locale === 'en') {
        htmlMsg = htmlMsgEn
        btnConfirm = btnConfirmEn
        btnDeny = btnDenyEn
    }

    var swalAlert = Swal.fire({
        position: 'center',
        icon: 'info',
        title: 'Informations',
        html: '<span style="line-height: 2rem; font-family: \'Work Sans\', sans-serif;">' + htmlMsg + '</span>',
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: btnConfirm,
        denyButtonText: btnDeny,
        reverseButtons: true,
        timer: 1000000,

    })

    swalAlert.then((result) => {
        if (result.isConfirmed) {
            if (urlToRedirect) {
                window.location.replace(urlToRedirect)
            }

        }
        if (result.isDenied) {
            console.log(urlCancelToRedirect)
            if (urlCancelToRedirect) {
                window.location.replace(urlCancelToRedirect)
            }

        }
    })

}

//Window.errorMessage = "";
if (Window.cartNotInitialized) {
    if (Window.cartNotInitialized == 1) {
        Swal.fire({
            position: 'center',
            icon: 'info',
            title: 'Informations',
            html: Window.errorMessage,
            showConfirmButton: true,
            timer: 1000000
        })
    }

}

//Window.errorMessage = "";
if (Window.emptyCart) {
    if (Window.emptyCart == 1) {
        Swal.fire({
            position: 'center',
            icon: 'info',
            title: 'Informations',
            html: Window.errorMessage,
            showConfirmButton: true,
            timer: 1000000
        })
    }

}
document.addEventListener("DOMContentLoaded", (event) => {

    // if (Window.displayModalTogo == 1) {
    //     $('#modalTogo').modal('show')
    // }

    // if (Window.displayModalDistrict && Window.displayModalTogo != 1) {
    //     $('#modalCustomerDistrict').modal('show')
    // }

    if (Window.displayPopinChoicesModal) {
        $('#popin_choices_modal').modal('show')
    }

    $(function () {
        $('[data-toggle="popover"]').popover({
            trigger: 'focus'
        })
    })
});


if (Window.displayAlertNewSite) {

    if (Window.displayAlertNewSite == 1 && Window.messageAlertNewSite != "") {
        Swal.fire({
            position: 'center',
            icon: 'info',
            title: 'Informations',
            html: Window.messageAlertNewSite,
            showConfirmButton: true,
            timer: 1000000
        })
    }

}

export function showDeliveryFeesNoteOnCart() {
    $(".cart-note-delivery-fees").removeClass("d-none");
}

export function tomDishOption(id, placeholder, maxItems = null, minItems = null,locale = "fr") {
    new TomSelect(id, {
        hideSelected: false,
        duplicates: true,
        maxItems: maxItems,
        minItems: minItems,
        plugins: ['remove_button'],
        persist: false,
        create: false,
        placeholder: placeholder,
       // controlInput: null,
        searchField: null,
        onFocus: function() {
            this.control_input.setAttribute("readonly", true);
            this.control.style.cursor = 'pointer';
        },
        onDropdownClose: function(params){
            if(this.settings.minItems != null && this.settings.minItems > this.items.length){
                let remainder = this.settings.minItems  - this.items.length;
                this.open()
                if(locale == "en"){
                    alert("You have "+remainder+" more option(s) to pick")
                }else{
                    alert("Il vous reste "+remainder+" option(s) à choisir")
                }

            }

        }

    });
}